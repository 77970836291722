<template>
  <div id="formulario-edit-info">
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
         <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable" color="danger">Excluir</vs-button>            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
            </div>
        </div>

        <div class="vx-row mt-2">            
            <div class="vx-col w-1/2"><label class="vs-input--label">Tipo</label></div>     
            <div class="vx-col w-1/2"><label class="vs-input--label" v-if="exibirConfiguracaoLeitorRFID || exibirConfiguracaoGeral">Configuração</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2">
                <v-select                                               
                    :options="CONSTANTE_TIPO_ACESSORIO_MODULO" 
                    v-model="data_local.tipo"    
                    :clearable="false"
                    @input="apagarTipoConfigurcao"   
                    :reduce="r => r.data"   
                    class="w-full vs-input--label">
                </v-select> 
            </div>     
            <div class="vx-col w-1/2" v-if="exibirConfiguracaoLeitorRFID">
                <v-select                                               
                    :options="CONSTANTE_TIPO_CONFIGURACAO_ACESSORIO_MODULO" 
                    v-model="data_local.tipoConfiguracao"    
                    :clearable="false"
                    :reduce="r => r.data"                                                     
                    class="w-full vs-input--label">
                </v-select> 
            </div>     
            <div class="vx-col w-1/2" v-if="exibirConfiguracaoGeral">
                <v-select                                               
                    :options="CONSTANTE_TIPO_CONFIGURACAO_GERAL_ACESSORIO_MODULO" 
                    v-model="data_local.tipoConfiguracao"    
                    :clearable="false"
                    :reduce="r => r.data"                                                     
                    class="w-full vs-input--label">
                </v-select> 
            </div>    
        </div>     
        <div class="vx-row mt-2">                           
            <div class="vx-col w-1/2"><label class="vs-input--label">Marca</label></div>   
            <div class="vx-col w-1/2"><label class="vs-input--label">Serial</label></div>      
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2">
                <v-select                                               
                    :options="CONSTANTE_MARCAS_ACESSORIO_MODULO" 
                    v-model="data_local.marca"    
                    :clearable="false"
                    :reduce="r => r.data"   
                    :disabled="registroOriginal.hasOwnProperty('id')"   
                    class="w-full vs-input--label">
                </v-select> 
            </div>
            <div class="vx-col w-1/2">
                <vs-input class="w-full" v-model="data_local.serial" :maxlength="255" :disabled="registroOriginal.hasOwnProperty('id')"  /> 
            </div>     
        </div>     
        <div class="vx-row mt-2">             
            <div class="vx-col w-1/2"><label class="vs-input--label">Modelo</label></div>     
            <div class="vx-col w-1/2"><label class="vs-input--label">Status</label></div>     
        </div>    
        <div class="vx-row">                            
            <div class="vx-col w-1/2">
                 <vs-input class="w-full" v-model="data_local.modelo" :maxlength="255"/> 
            </div>
            <div class="vx-col w-1/2">
                 <v-select                                               
                    :options="CONSTANTE_STATE_ACESSORIO" 
                    :reduce="r => r.data"
                    :clearable="false"
                    :disabled="(registroOriginal.hasOwnProperty('id') && registroOriginal.stateAcessorio === 'ATIVO') || !registroOriginal.hasOwnProperty('id') "
                    v-model="data_local.stateAcessorio"                      
                    class="w-full vs-input--label">
                </v-select>  
            </div>     
        </div>       
        <div class="vx-row mt-2">
            <div class="vx-col w-1/2"><label class="vs-input--label mr-2">Cliente Proprietário</label></div>     
            <div class="vx-col w-1/2"><label class="vs-input--label mr-2">Cliente com Posse</label></div>     
            
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2">
                 <v-select  
                    label="nome"                                             
                    :reduce="r => r.id"
                    :options="listaClientes" 
                    v-model="data_local.idClienteProprietario"                      
                    class="w-full vs-input--label">
                </v-select>  
            </div>     
            <div class="vx-col w-1/2">
                 <v-select
                    label="nome"  
                    :reduce="r => r.id"                                           
                    :options="listaClientes" 
                    v-model="data_local.idClienteComPosse"                      
                    class="w-full vs-input--label">
                </v-select> 
            </div>             
        </div>   

        <div class="vx-row mt-2">
            <div class="vx-col w-1/3" v-if="data_local.stateAcessorio === 'COM TECNICO'"><label class="vs-input--label mr-2">Técnico com Posse</label></div>     
            <div class="vx-col w-2/3"><label class="vs-input--label mr-2">Observação</label></div>     
        </div>    
        <div class="vx-row">    
            <div class="vx-col w-1/3" v-if="data_local.stateAcessorio === 'COM TECNICO'">
                 <v-select                                               
                    label="nome"         
                    :reduce="r => r.id"                                    
                    :options="listaTecnicos" 
                    v-model="data_local.idTecnico"                      
                    class="w-full vs-input--label">
                </v-select> 
            </div>    
            <div class="vx-col w-2/3">
                <vs-textarea v-model="data_local.observacao" class="ml-1" />
            </div>
        </div>
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"

import { CONSTANTE_STATE_ACESSORIO } from '@/constantesComboBox.js'
import { CONSTANTE_MARCAS_ACESSORIO_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_ACESSORIO_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_CONFIGURACAO_ACESSORIO_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_CONFIGURACAO_GERAL_ACESSORIO_MODULO } from '@/constantesComboBox.js'

const URL_SERVLET = "/AcessorioModuloRest";
const NAME_ROUTE_SEARCH = "cadastro-acessorios-modulos-pesquisar";

export default {    
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {marca: null, serial: null, tipo: null, modelo: null, stateAcessorio: 'EM ESTOQUE', idClienteProprietario: null, tipoConfiguracao: null,
            idTecnico: null, idClienteComPosse: null, observacao: null}
       }
    },
  },
  data() {
    return {            
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),
        listaClientes : [],
        listaTecnicos: [],
    }
  },  
  created(){
      this.CONSTANTE_MARCAS_ACESSORIO_MODULO = CONSTANTE_MARCAS_ACESSORIO_MODULO;
      this.CONSTANTE_STATE_ACESSORIO = CONSTANTE_STATE_ACESSORIO;
      this.CONSTANTE_TIPO_ACESSORIO_MODULO = CONSTANTE_TIPO_ACESSORIO_MODULO;
      this.CONSTANTE_TIPO_CONFIGURACAO_ACESSORIO_MODULO = CONSTANTE_TIPO_CONFIGURACAO_ACESSORIO_MODULO;
      this.CONSTANTE_TIPO_CONFIGURACAO_GERAL_ACESSORIO_MODULO = CONSTANTE_TIPO_CONFIGURACAO_GERAL_ACESSORIO_MODULO;
  }, 
  mounted() {
      this.fetchListaClientes();
      this.fetchListaTecnicos();
  },
  computed: {    
    exibirConfiguracaoLeitorRFID() {
        return this.data_local.tipo === 'LEITOR_RFID';
    },    
    exibirConfiguracaoGeral() {
        return this.data_local.tipo === 'GENERICO' || this.data_local.tipo === 'SENSOR_CHUVA' || this.data_local.tipo === 'SENSOR_CINTO_SEGURANCA'
        || this.data_local.tipo === 'SENSOR_TAMPA_TANQUE_COMBUSTIVEL' || this.data_local.tipo === 'SENSOR_PORTA' || this.data_local.tipo === 'SENSOR_SIMPLES';
    },    
    isDeletable() {
       let perfilLogado = this.$store.state.AppActiveUser.perfil;
       if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || perfilLogado === "SUPERVISOR" 
            || perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {
            return false;	
       } 

       return this.data_local != null && this.data_local.hasOwnProperty("id");
    },    
  },
  methods: {  
    apagarTipoConfigurcao(){
        this.data_local.tipoConfiguracao = null;
    }, 
    fetchListaClientes() {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      
      axios.post("/ClientesRest", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("id") && response.data.id === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.listaClientes = response.data;         
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    }, 
    fetchListaTecnicos() {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      
      axios.post("/TecnicoRest", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("id") && response.data.id === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.listaTecnicos = response.data;         
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    }, 
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('id', this.data_local.id);   
        params.append('marca', this.data_local.marca);      
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("id") && response.data.id === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            
            if (this.registroOriginal.hasOwnProperty("id")) {
                params.append('id', this.data_local.id);   
                params.append('method', 'Update');      
            }  else {
                params.append('method', 'Insert');      
            }  
            
            params.append('marca', this.data_local.marca);      
            params.append('modelo', this.data_local.modelo);      
            params.append('serial', this.data_local.serial);      
            params.append('tipo', this.data_local.tipo);
            params.append('stateAcessorio', this.data_local.stateAcessorio);     
            
            if (this.data_local.idClienteProprietario) {  params.append('idClienteProprietario', this.data_local.idClienteProprietario); }
            if (this.data_local.idTecnico) {  params.append('idTecnico', this.data_local.idTecnico); }    
            if (this.data_local.observacao) {  params.append('observacao', this.data_local.observacao); }
            if (this.data_local.idClienteComPosse) {  params.append('idClienteComPosse', this.data_local.idClienteComPosse); }
            if (this.data_local.tipoConfiguracao) {  params.append('tipoConfiguracao', this.data_local.tipoConfiguracao); }
                    
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("id") && response.data.id === "99" ) {
                    this.$router.push({ path: '/' });
                } else {                    
                    if (response.data.hasOwnProperty("error")) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];

        if (!this.data_local.marca) {
            this.errorsValidacao.push("Marca obrigatório");
        }

        if (!this.data_local.modelo) {
            this.errorsValidacao.push("Modelo obrigatório");
        }

        if (!this.data_local.tipo) {
            this.errorsValidacao.push("Tipo obrigatório");
        }

        if (!this.data_local.serial) {
            this.errorsValidacao.push("Serial obrigatório");
        }
        
        if (this.data_local.stateAcessorio === "DE TERCEIRO" && !this.data_local.idClienteProprietario) {
            this.errorsValidacao.push("Selecione o cliente proprietário do módulo.");
        }
        
        if (this.data_local.stateAcessorio === "ATIVO" && this.data_local.stateAcessorio !== "ATIVO") {
            this.errorsValidacao.push("O status ativo só pode ser alterado através da Ativação do Módulo em um veículo.");
        }

        if (this.data_local.stateAcessorio === "COM CLIENTE" && !this.data_local.idClienteComPosse) {
            this.errorsValidacao.push("Selecione o cliente que está com a posse do módulo.");
        }
        
        if (this.data_local.stateAcessorio === "COM TECNICO" && !this.data_local.idTecnico) {
            this.errorsValidacao.push("Selecione o técnico.");
        }

        let perfilLogado = this.$store.state.AppActiveUser.perfil;
            if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || perfilLogado === "SUPERVISOR" 
            || perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {  
                let status = this.data_local.stateAcessorio;      
                if (status === "PERDIDO" || status === "ROUBADO" || status === "QUEIMADO" || 
                    status === "QUEBRADO" || status === "INATIVO") {
                    this.errorsValidacao.push("Você não tem permissão para alterar o status do acessório. ");		
                }
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        
      return false;
      
    },         
  }
}
</script>